import {
    Box,
    Text,
    Popover,
    ActionIcon,
} from "@mantine/core";
import GenerationMenu from "./GenerationMenu.jsx";

function OptionsBar({
    node,
    changeAnswer,
    changeQuestion,
    addChild,
    removeChild,
    parentNode,
    dispAns=true,
    setDispAns,
    dispChildren,
    setDispChildren,
    setCurrent,
}){

        const opStyle ={
        color:'lightseagreen',
        marginLeft:'5px',
        cursor:'pointer',
        fontWeight:'bold',
    }

    // We make sure children are shown when addChild happens
    function ChildAndShow(){
        addChild(node);
        setDispChildren(true);
    }

    return (
        <Box style={{
            display:'flex',
            alignItems:'center',
            justifyContent:'space-between',
            width:'100%',
            marginRight:'2px'
        }}> 
            <Box display='flex'>
                {node.children.length !== 0 && <ActionIcon style={opStyle} onClick={() => setDispChildren(!dispChildren)} size='xs'>{dispChildren?<img src="./Icons/caret-down.svg" height="16"width="16"/>:<img src='./Icons/caret-right.svg' width='16' height='16'/>}</ActionIcon>}
                <Popover>
                    <Popover.Target>
                        <Text size='xs' style={opStyle}>AutoQuestions</Text>
                    </Popover.Target>
                    <Popover.Dropdown>
                        <GenerationMenu
                            node={node}
                            addChild={addChild}
                            setDispChildren={setDispChildren}
                            />
                    </Popover.Dropdown>
                </Popover>
                <Text onClick={() => setDispAns(!dispAns)} size='xs' td={(!dispAns && node.answer.length > 0)?'underline':'normal'} style={opStyle}> {dispAns? "HideAnswer":"Answer"}</Text>
                <ActionIcon size='xs' onClick={ChildAndShow} style={opStyle} height='16' width='16'><img src="./Icons/plus.svg" height="16"width="16"/> </ActionIcon>
                <ActionIcon onClick={() => setCurrent(node)} size='xs' style={opStyle}> <img src="./Icons/arrow-big-right-line.svg" height="16"width="16"/> </ActionIcon>
            </Box>

            <Box>
             <ActionIcon onClick={() => removeChild(parentNode, node)} size='xs'> <img src="./Icons/trash.svg" height="16"width="16"/> </ActionIcon>
            </Box>

        </Box>
    )
}

export default OptionsBar;