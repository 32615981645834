
export function createNode(inpQ, parent, inpA=""){
    let retVal = {
        question:inpQ,
        answer: inpA,
        children:[],
        //Lol, not guaranteed to be random, but fine for now.
        uuid:Math.floor(Math.random()*999999999999999999),
        parent:parent,
    }

    return retVal
}