import {
    Box,
    Text,
    Tabs,
    Button,
} from "@mantine/core";
import AiGenMenu from "./AIGenMenu.jsx";
import  SelectableText  from './SelectableText.jsx';

function GenerationMenu({
    node,
    changeAnswer,
    changeQuestion,
    addChild,
    removeChild,
    parentNode,
    dispAns=true,
    setDispAns,
    setDispChildren,
}){
    // Just the node & add child 
    // How might we include the parent?
    // Call backend for questions
    function requestQuestions(){

    }

    const opStyle ={
        color:'lightseagreen',
        marginLeft:'5px',
        cursor:'pointer',
        fontWeight:'bold',
    }

    const DefaultQuestions = [
        "Why?",
        "What if?",
        "How can we break the idea into smaller parts?",
        "How can we restate the problem?",
        "What if we moved backwards?",
        "What is this related to?",
        "How can we simplify this?",
        "What is the best/worst case?",
        "What is our priority?",
    ]

    // Function for when question is picked.
    function Picked(inpText){
        addChild(node, inpText);
        if(setDispChildren !== undefined){
            setDispChildren(true)
        }
    }

    return (
        <Box>
            <Text>Questions:</Text>
            <Box style={{display:'flex', height:'100%', flexDirection:'column', justifyContent:'space-between', marginTop:'10px'}}>
                            {DefaultQuestions.map(t =>
                                <Button key={t} variant="subtle" size='xs' style={{color:'lightseagreen', display:'flex'}} onClick={() => Picked(t) }> {t}</Button> )}
            </Box> 
        </Box>
    )
    /*
    return (
        <Box>
            <Tabs variant='outline' defaultValue="Default">
                <Tabs.List>
                    <Tabs.Tab value="Default">Default Questions</Tabs.Tab>
                    <Tabs.Tab value="Generation">Generate Questions</Tabs.Tab>
                </Tabs.List>
                <Box style={{width:"400px", height:"300px", overflow:"scroll"}}>
                    <Tabs.Panel value="Default" style={{height:'100%'}}>
                        <Box style={{display:'flex', height:'100%', flexDirection:'column', justifyContent:'space-between', marginTop:'10px'}}>
                            {DefaultQuestions.map(t =>
                                <Button variant="subtle" size='xs' style={{color:'lightseagreen', display:'flex'}} onClick={() => Picked(t) }> {t}</Button> )}
                        </Box>
                    </Tabs.Panel>
                    <Tabs.Panel value="Generation">
                            <AiGenMenu node={node} changeAnswer={changeAnswer} changeQuestion={changeQuestion} parentNode={parentNode} addChild={addChild} Picked={Picked}/>
                    </Tabs.Panel>
                </Box>

            </Tabs>
        </Box>
    )*/
}

export default GenerationMenu;