import {
    useState,
    useRef,
    useEffect
} from 'react'
import {
    Box,
    Text,
    Textarea,
    TextInput,
} from "@mantine/core";
import OptionsBar from "./OptionsBar.jsx";

function QuestionCard({
    node,
    changeAnswer,
    changeQuestion,
    addChild,
    removeChild,
    parentNode,
    leftMargin,
    setCurrent,
    level
}){
    const [dispAns, setDispAns] = useState(!!node.answer);
    const [dispChildren, setDispChildren] = useState(true);
    const qRef = useRef(null)
    const aRef = useRef(null)

    // Just learning how to implement key bindings
    // We will need them for future QOL!
    useEffect( () => {
        const handleKeyDown = (event) => {
            if(event.target === qRef.current || event.target === aRef.current){
                if(event.ctrlKey && event.key === "a"){
                    addChild(node)
                }
            }
        };
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        }
    }, [qRef, aRef, addChild, node] )

    return (
        <Box style={{
            padding:'2px',
            marginBottom:'5px',
            marginTop:'5px',
            marginLeft:leftMargin.toString()+'px',
            boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.3)', 
            borderRadius: '5px',
            backgroundColor: level%2?'white':'AliceBlue',
        }}>

            <Box style={{display:'flex',justifyContent:'space-between' }}>
                <OptionsBar node={node} addChild={addChild} parentNode={parentNode} removeChild={removeChild} dispAns={dispAns} setDispAns={setDispAns} dispChildren={dispChildren} setDispChildren={setDispChildren} setCurrent={setCurrent}/>
            </Box>

            <Box style={{
                display:'flex',
                width:'100%',
                alignItems:'center'}}>
                <Box style={{display:'flex', alignItems:'center', flexGrow:'1', marginLeft:'2px'}}>
                    <Text>Question:</Text>
                    <Box style={{flexGrow:'1', margin:'1px'}}>
                        <Textarea
                            ref={qRef}
                            value={node.question}
                            minRows={1}
                            maxRows={1}
                            onChange={e => changeQuestion(node, e.target.value)}    
                            styles={() => ({
                                input:{'&:focus-within':{borderColor:'teal'}}})}
                            radius='xs' size='xs'/> 
                    </Box>
                </Box>
            </Box>

            {dispAns && 
            <Box style={{display:'flex'}}>
                    <Box style={{flexGrow:'1', marginLeft:'3px'}}>
                        <Text>Answer:</Text>
                        <Textarea
                            ref={aRef}
                            size='xs'
                            autosize
                            value={node.answer}
                            onChange={e => changeAnswer(node, e.target.value)}
                            variant='filled'
                            radius='xs'
                            styles={() => ({input:{'&:focus-within':{borderColor:'teal'},backgroundColor:'#D8D8D8' }})}/>
                    </Box>
            </Box>}

            <Box style={{marginBottom:"5px"}}/>
            {
            dispChildren && node.children.map((child,idx) => (
                <QuestionCard
                    key={child.uuid}
                    node={child}
                    changeAnswer={changeAnswer}
                    changeQuestion={changeQuestion}
                    addChild={addChild}
                    removeChild={removeChild}
                    parentNode={node}
                    leftMargin={5}
                    setCurrent={setCurrent}
                    level={level+1}
                    />
            ))}
            
        </Box>
    )
}

export default QuestionCard;