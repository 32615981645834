import {
    React,
    useRef
} from 'react';
import {
    Box,
    Text,
    Menu,
    Popover,
    ActionIcon,
    Button,
} from "@mantine/core";
import GenerationMenu from "./GenerationMenu.jsx";
import { useState } from 'react';
import { useEffect } from 'react';

function RootBar({
    setRoot,
    root,
    changeAnswer,
    changeQuestion,
    addChild,
    removeChild,
    parentNode,
    dispAns=true,
    setDispAns,
    current,
    setCurrent,
    setCurrentFlag,
}){
    const fileInputRef = useRef(null);

    const opStyle ={
        color:'lightseagreen',
        marginLeft:'5px',
        cursor:'pointer',
        fontWeight:'bold',
    }

    function stringify(obj) {
      if (typeof obj === "object" && obj !== null) {
        if (Array.isArray(obj)) {
          let result = "[";
          for (let i = 0; i < obj.length; i++) {
            let val = stringify(obj[i]);
            if (val !== undefined) {
              result += val;
              if (i < obj.length - 1) {
                result += ",";
              }
            }
          }
          result += "]";
          return result;
        } else {
          let result = "{";
          let tmp = obj
          delete tmp.parent
          let keys = Object.keys(tmp);
          for (let i = 0; i < keys.length; i++) {
            if(keys[i] !== 'parent'){
              let key = stringify(keys[i]);
              let val = stringify(tmp[keys[i]]);
              if (key !== undefined && val !== undefined) {
                result += `${key}:${val}`;
                if (i < keys.length - 1) {
                  result += ",";
                }
              }
           }
          }
          result += "}";
          return result;
        }
      } else if (typeof obj === "string") {
        return JSON.stringify(obj)
        .replace(/\u0000-\u001f|\u007f-\u009f|\u00ad|\u0600-\u0604|\u070f|\u17b4|\u17b5|\u200c-\u200f|\u2028-\u202f|\u2060-\u206f|\ufeff|\ufff0-\uffff/g, function(match) {
          return '\\u' + match.charCodeAt(0).toString(16).padStart(4, '0');
        })
        .replace(/\n/g, '\\n');
        
      } else if (typeof obj === "number" || typeof obj === "boolean" || obj === null) {
        return String(obj);
      } else {
        return undefined;
      }
    }

    // Taken from Tan Li Hau
    //https://lihautan.com/json-parser-with-javascript/#summary
    function fakeParseJSON(str) {
        let i = 0;
      
        return parseValue();
      
        function parseObject() {
          if (str[i] === "{") {
            i++;
            skipWhitespace();
      
            const result = {};
      
            let initial = true;
            // if it is not '}',
            // we take the path of string -> whitespace -> ':' -> value -> ...
            while (str[i] !== "}") {
              if (!initial) {
                eatComma();
                skipWhitespace();
              }
              const key = parseString();
              skipWhitespace();
              eatColon();
              const value = parseValue();
              result[key] = value;
              initial = false;
            }
            // move to the next character of '}'
            i++;
      
            return result;
          }
        }
      
        function parseArray() {
          if (str[i] === "[") {
            i++;
            skipWhitespace();
      
            const result = [];
            let initial = true;
            while (str[i] !== "]") {
              if (!initial) {
                eatComma();
              }
              const value = parseValue();
              result.push(value);
              initial = false;
            }
            // move to the next character of ']'
            i++;
            return result;
          }
        }
      
        function parseValue() {
          skipWhitespace();
          const value =
            parseString() ??
            parseNumber() ??
            parseObject() ??
            parseArray() ??
            parseKeyword("true", true) ??
            parseKeyword("false", false) ??
            parseKeyword("null", null);
          skipWhitespace();
          return value;
        }
      
        function parseKeyword(name, value) {
          if (str.slice(i, i + name.length) === name) {
            i += name.length;
            return value;
          }
        }
      
        function skipWhitespace() {
          while (
            str[i] === " " ||
            str[i] === "\n" ||
            str[i] === "\t" ||
            str[i] === "\r"
          ) {
            i++;
          }
        }
      
        function parseString() {
          if (str[i] === '"') {
            i++;
            let result = "";
            while (str[i] !== '"') {
              if (str[i] === "\\") {
                const char = str[i + 1];
                if (
                  char === '"' ||
                  char === "\\" ||
                  char === "/" ||
                  char === "b" ||
                  char === "f" ||
                  char === "n" ||
                  char === "r" ||
                  char === "t"
                ) {
                  result += char;
                  i++;
                } else if (char === "u") {
                  if (
                    isHexadecimal(str[i + 2]) &&
                    isHexadecimal(str[i + 3]) &&
                    isHexadecimal(str[i + 4]) &&
                    isHexadecimal(str[i + 5])
                  ) {
                    result += String.fromCharCode(
                      parseInt(str.slice(i + 2, i + 6), 16)
                    );
                    i += 5;
                  }
                }
              } else {
                result += str[i];
              }
              i++;
            }
            i++;
            return result;
          }
        }
      
        function isHexadecimal(char) {
          return (
            (char >= "0" && char <= "9") ||
            (char.toLowerCase() >= "a" && char.toLowerCase() <= "f")
          );
        }
      
        function parseNumber() {
          let start = i;
          if (str[i] === "-") {
            i++;
          }
          if (str[i] === "0") {
            i++;
          } else if (str[i] >= "1" && str[i] <= "9") {
            i++;
            while (str[i] >= "0" && str[i] <= "9") {
              i++;
            }
          }
      
          if (str[i] === ".") {
            i++;
            while (str[i] >= "0" && str[i] <= "9") {
              i++;
            }
          }
          if (str[i] === "e" || str[i] === "E") {
            i++;
            if (str[i] === "-" || str[i] === "+") {
              i++;
            }
            while (str[i] >= "0" && str[i] <= "9") {
              i++;
            }
          }
          if (i > start) {
            return Number(str.slice(start, i));
          }
        }
      
        function eatComma() {
          if (str[i] !== ",") {
            throw new Error('Expected ",".');
          }
          i++;
        }
      
        function eatColon() {
          if (str[i] !== ":") {
            throw new Error('Expected ":".');
          }
          i++;
        }
    }
    
    // Save the whole thread as a json file.
    const saveThread = async (input) => {
        const file = new Blob([stringify(input)], {type:'json'})
        const a = document.createElement('a');
        a.href = URL.createObjectURL(file)
        a.download = 'question-track.json';
        a.addEventListener('click', (e) => {
            setTimeout(() => URL.revokeObjectURL(a.href), 30*1000);
        });
        a.click();
    }
    
    // Crawl through and add parent to each obj
    function addParents(parent, obj){
        obj.parent = parent
        for(let i = 0 ; i < obj.children.length;i++){
            addParents(obj, obj.children[i])
        } 
    }

    // Load up a thread from a json file.
    //TODO: Throw Errors properly
    function loadThread(e){
        // Create reader
        let rd = new FileReader()
        rd.onload = function (event){
            let contents = event.target.result;
            let data = fakeParseJSON(contents);
            data.parent = undefined;
            // Now recursively walk through and create the nodes
            // Throw error if anything is wrong.
            for(let i = 0; i < data.children.length; i++){
                addParents(data, data.children[i])
            }
            setRoot(data);
            setCurrentFlag(true);

        }
        rd.readAsText(e.target.files[0])
    }

    const handleFileClick = () => {
        fileInputRef.current.click();
    }

    // Rending the route for the top bar
    const [route, setRoute] = useState([])
    function CreateQuestionRoute(currentNode, rootNode){
      let retVal = []
      let tmp = currentNode;
    
      while(tmp !== rootNode){
        if(tmp === undefined){
          break
        }
        let qStr = tmp.question.length > 15 ? tmp.question.slice(0,15)+'...' : tmp.question;
        if(qStr === ""){
          qStr = '\\'
        }
        retVal.unshift([qStr, tmp])
        tmp = tmp.parent
      }
      retVal.pop()
      setRoute(retVal);
    }
    useEffect(() => {
      CreateQuestionRoute(current, root)
    }, [current, root])
    useEffect(() => {})
    return (
        <Box style={{
            display:'flex',
        }}>
            {root.uuid !== current.uuid  && <ActionIcon onClick={() => {setCurrent(root)}} size='xs'> <img src="./Icons/home.svg" height="16"width="16"/> </ActionIcon>}
            <Text size='xs' style={opStyle} onClick={handleFileClick}>Load</Text>
            <Text size='xs' style={opStyle} onClick={() => saveThread(root)}>Save</Text>
            <input type='file' accept=".json" ref={fileInputRef} style={{display:'none'}} onChange={loadThread}/>
            
            <Box style={{display:'flex', overflowX:'auto'}}>
              {route.length > 0 && <Text style={{marginLeft:'5px', marginRight:'5px', color:'lightseagreen',  fontWeight:'bold'}} size='xs'>Route:</Text>}
              {route.length > 0 && route.map((item,i) => (<Text onClick={() => {setCurrent(item[1])}} size='xs' style={{whiteSpace:'nowrap', marginBottom:'5px', marginRight:'4px', cursor:'pointer', color:'lightseagreen'}} key={i}>{item[0] + ' -> '}</Text>))}
            </Box>
        </Box>
    )
}

export default RootBar;