import {
  useState,
  useEffect,
  createContext,
} from 'react';
import {
  Box,
  Textarea,
  Text,
  Modal,
  Image,
  Button,
} from '@mantine/core';
import {createNode} from './createNode.jsx';
import QuestionCard from './QuestionCard.jsx';
import RootBar from "./RootBar.jsx";
import {GenStateContext} from './GenStateContext.jsx';

function App() {
  const getBaseUrl = 'http://localhost:6660'
  const [root, setRoot] = useState(createInitRoot());
  const [current, setCurrent] = useState(root);

  const [openModal, setOpenModal] = useState(false);
  const [currentFlag, setCurrentFlag] = useState(false);

  const [genQueryInput, setGenQueryInput] = useState({});
  const [genQuestionList, setGenQuestionList] = useState([]);
  const [queryGen, setQueryGen] = useState(false);

  function createInitRoot(){
    let initNode = createNode("")
    //initNode.children.unshift(createNode("", initNode))
    return initNode
  }

  // 
  async function PollUntilFinish(jobId){
    let response, data, retries = 0;
    try{
      while(retries < 100){
        response = await fetch(getBaseUrl+'/qResults/'+jobId, {method:'GET'});
        if(response.status === 200){
          data = await response.json()
          setGenQuestionList(genQuestionList.concat(data['Questions']))
          //setGenQuestionList((data['Questions']))
          setQueryGen(false);
          return
        } else if(response.status !== 202){
          throw new Error('Queue job failed to proces with status ${response.status}') 
        } else {
          retries += 1;
          // Sleep for 0.1 seconds before retrying
          await new Promise( resolve => setTimeout(resolve, 150));
          continue;
        }
      }
      throw new Error('Max retries!!!');
    } catch(error) {
      console.log('Try Error')
      console.log(error)
      setQueryGen(false);
    }
  }

  function CreatePrompt(node, userInput) {
    let context = '';

    // Add inputNode question and answer
    context += node.question + '\n' + node.answer + '\n';
    
    return context;
  }
  
  
  // QueryGen locks new calls to generation
  // After generation set to zero
  //TODO: Handle Full Queue
  async function  queryQuestion(){
    // We do this to prevent Infinite Loops
    if(queryGen === true){
      //TODO: Add context function here
      // Input: node & userInput
      const context = CreatePrompt(genQueryInput["node"], genQueryInput["userInput"])
      console.log("Begin")
      console.log(context)
      const requestParams = {
        method:"POST",
        headers:{'Content-type':'application/json'},
        body:JSON.stringify({
          context:context,
          mode:genQueryInput['userInput'],
        })
      }

      try{      
      fetch(getBaseUrl+'/genQuestion',requestParams)
      .then( (resp) => {
        // Making Request
        if(resp.ok){
          return resp.text()
        } else {
          setQueryGen(false)
          throw new Error('Question Generation Response Not Ok')
        }})
      .then(jobId => {
        PollUntilFinish(jobId)
      }).catch(error => {
        console.log(error)
        setQueryGen(false)
      })
    }catch(error){
      console.log(error)
      setQueryGen(false)
    }

    }
  }

  useEffect(() =>{
    queryQuestion()
  }, [queryGen, ])

  function changeQuestion(node, newQuestion){
    node.question = newQuestion
    if(node.uuid === root.uuid){
      console.log(node.question)
      setRoot({...node})
      
    } else {
      setRoot({...root})
    }
  }
  function changeAnswer( node, newAnswer){
    node.answer = newAnswer
    setRoot({...root})
  }
  function addChild(node, inputTxt=""){
    node.children.unshift(createNode(inputTxt, node))
    setRoot({...root})
  }
  function removeChild(parentNode, childNode){
    let idx = parentNode.children.indexOf(childNode)
    parentNode.children.splice(idx, 1)
    setRoot({...root})
  }

  useEffect(()=>{
    if(currentFlag){
      setCurrent(root);
      setCurrentFlag(false);
    }
  }, [root, currentFlag])

  return (
    <GenStateContext.Provider value={{
      queryGen:queryGen, 
      setQueryGen:setQueryGen, 
      genQuestionList:genQuestionList, 
      setGenQuestionList:setGenQuestionList,
      setGenQueryInput:setGenQueryInput}}>

      <Box style={{margin:'10px'}}>
        <Modal opened={openModal} onClose={()=>setOpenModal(false)}>
          <Box>
            <Text>Welcome to QuestionTrack, a tool for question-driven thinking!</Text>
            <Text>QuestionTrack is a web application designed to help you keep track of your questions and train of thought. With this tool, you can easily organize your questions, prioritize them, and follow your train of thought as you explore ideas!</Text>
            <Image src='./Images/example.png'/>
          </Box>
        </Modal>
        <Box className='header' style={{
          display:'flex',
          marginBottom:'10px',
          width:'100%',
          justifyContent: 'space-between' }}>
          
          <h2> Question Track </h2>
          <Text size='xs' onClick={() => setOpenModal(true)}
            style={{flexBasis:'auto', color:'lightseagreen',cursor:'pointer',fontWeight:'bold'}}>What is this?</Text>
        </Box>
        <Box className='RootText' style={{marginBottom:'10px'}}>
            <RootBar root={root} setRoot={setRoot} current={current} setCurrent={setCurrent} setCurrentFlag={setCurrentFlag}/>
            <hr/>
            <QuestionCard
              key={current.uuid}
              node={current}
              changeAnswer={changeAnswer}
              changeQuestion={changeQuestion}
              addChild={addChild}
              removeChild={removeChild}
              parentNode={null}
              leftMargin={10}
              setCurrent={setCurrent}
              level={0}
              />
        </Box>

      </Box>
    </GenStateContext.Provider>


  );
}
export default App;
